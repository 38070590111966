import { Contract } from '@ethersproject/contracts'
import { TransactionResponse } from '@ethersproject/providers'
import { ETHER, Percent, Token } from '@uniswap/sdk'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { ArrowDown, Plus } from 'react-feather'
import ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { LightCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import ConfirmationModal from '../../components/ConfirmationModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { RowBetween, RowFixed } from '../../components/Row'

import Slider from '../../components/Slider'
import CurrencyLogo from '../../components/CurrencyLogo'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { useMooniswapContract } from '../../hooks/useContract'

import { useTransactionAdder } from '../../state/transactions/hooks'
import { StyledInternalLink, TYPE } from '../../theme'
import { calculateGasMargin, calculateSlippageAmount } from '../../utils'
import { currencyId } from '../../utils/currencyId'
import AppBody from '../AppBody'
import { ClickableText, MaxButton, Wrapper } from '../Pool/styleds'
import { useBurnActionHandlers, useBurnState, useDerivedBurnInfo } from '../../state/burn/hooks'
import { Field } from '../../state/burn/actions'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { BigNumber } from '@ethersproject/bignumber'

export default function RemoveLiquidity({
  history,
  match: {
    params: { currencyIdA, currencyIdB }
  }
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const [currencyA, currencyB] = [useCurrency(currencyIdA) ?? undefined, useCurrency(currencyIdB) ?? undefined]
  const { account, chainId, library } = useActiveWeb3React()
  const [tokenA, tokenB] = useMemo(() => [currencyA, currencyB], [
    currencyA,
    currencyB
  ])

  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // burn state
  const { independentField, typedValue } = useBurnState()
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(currencyA ?? undefined, currencyB ?? undefined)
  const { onUserInput: _onUserInput } = useBurnActionHandlers()
  const isValid = !error
  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showDetailed, setShowDetailed] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('')
  const [allowedSlippage] = useUserSlippageTolerance()

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
        ? '<1'
        : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A ? typedValue : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B ? typedValue : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? ''
  }

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(new Percent('1'))

  // pair contract
  const pairContract: Contract | null = useMooniswapContract(pair?.liquidityToken?.address)

  // allowance handling
  const [, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  // const [, approveCallback] = useApproveCallback(parsedAmounts[Field.LIQUIDITY], pairContract?.address)

  // async function onAttemptToApprove() {
  //   if (!pairContract || !pair || !library) throw new Error('missing dependencies')
  //   const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
  //   if (!liquidityAmount) throw new Error('missing liquidity amount')
  //   // try to gather a signature for permission
  //   const nonce = await pairContract.nonces(account)
  //
  //   const deadlineForSignature: number = Math.ceil(Date.now() / 1000) + deadline
  //
  //   const EIP712Domain = [
  //     { name: 'name', type: 'string' },
  //     { name: 'version', type: 'string' },
  //     { name: 'chainId', type: 'uint256' },
  //     { name: 'verifyingContract', type: 'address' }
  //   ]
  //   const domain = {
  //     name: 'Mooniswap',
  //     version: '1',
  //     chainId: chainId,
  //     verifyingContract: pair.liquidityToken.address
  //   }
  //   const Permit = [
  //     { name: 'owner', type: 'address' },
  //     { name: 'spender', type: 'address' },
  //     { name: 'value', type: 'uint256' },
  //     { name: 'nonce', type: 'uint256' },
  //     { name: 'deadline', type: 'uint256' }
  //   ]
  //   const message = {
  //     owner: account,
  //     spender: pairContract.address,
  //     value: liquidityAmount.raw.toString(),
  //     nonce: nonce.toHexString(),
  //     deadline: deadlineForSignature
  //   }
  //   const data = JSON.stringify({
  //     types: {
  //       EIP712Domain,
  //       Permit
  //     },
  //     domain,
  //     primaryType: 'Permit',
  //     message
  //   })
  //
  //   library
  //     .send('eth_signTypedData_v4', [account, data])
  //     .then(splitSignature)
  //     .then(signature => {
  //       setSignatureData({
  //         v: signature.v,
  //         r: signature.r,
  //         s: signature.s,
  //         deadline: deadlineForSignature
  //       })
  //     })
  //     .catch(error => {
  //       // for all errors other than 4001 (EIP-1193 user rejected request), fall back to manual approve
  //       if (error?.code !== 4001) {
  //         approveCallback()
  //       }
  //     })
  // }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      setSignatureData(null)
      return _onUserInput(field, typedValue)
    },
    [_onUserInput]
  )

  const onLiquidityInput = useCallback((typedValue: string): void => onUserInput(Field.LIQUIDITY, typedValue), [
    onUserInput
  ])
  const onCurrencyAInput = useCallback((typedValue: string): void => onUserInput(Field.CURRENCY_A, typedValue), [
    onUserInput
  ])
  const onCurrencyBInput = useCallback((typedValue: string): void => onUserInput(Field.CURRENCY_B, typedValue), [
    onUserInput
  ])

  // tx sending
  const addTransaction = useTransactionAdder()

  async function onRemove() {
    if (!chainId || !library || !account) throw new Error('missing dependencies')
    const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts
    if (!currencyAmountA || !currencyAmountB || !pairContract) {
      throw new Error('missing currency amounts')
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(currencyAmountA, allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(currencyAmountB, allowedSlippage)[0]
    }

    if (!currencyA || !currencyB) throw new Error('missing tokens')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    if (!tokenA || !tokenB) throw new Error('could not wrap')

    let methodNames: string[] = ['withdraw']
    let args: Array<string | string[] | number | boolean> = [
      liquidityAmount.raw.toString(),
      [
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString()
      ]
    ]

    const safeGasEstimates = await Promise.all(
      methodNames.map(methodName =>
        pairContract?.estimateGas[methodName](...args)
          .then(calculateGasMargin)
          .catch((error: any) => {
            console.error(`estimateGas failed for ${ methodName }`, error)
          })
      )
    )

    const indexOfSuccessfulEstimation = safeGasEstimates.findIndex(safeGasEstimate =>
      BigNumber.isBigNumber(safeGasEstimate)
    )

    // all estimations failed...
    if (indexOfSuccessfulEstimation === -1) {
      console.error('This transaction would fail. Please contact support.')
    } else {
      const methodName = methodNames[indexOfSuccessfulEstimation]
      const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation]

      setAttemptingTxn(true)
      await pairContract[methodName](...args, {
        gasLimit: safeGasEstimate
      })
        .then((response: TransactionResponse) => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary:
              'Remove ' +
              parsedAmounts[Field.CURRENCY_A]?.toSignificant(3) +
              ' ' +
              currencyA?.symbol +
              ' and ' +
              parsedAmounts[Field.CURRENCY_B]?.toSignificant(3) +
              ' ' +
              currencyB?.symbol
          })

          setTxHash(response.hash)

          ReactGA.event({
            category: 'Liquidity',
            action: 'Remove',
            label: [currencyA?.symbol, currencyB?.symbol].join('/')
          })
        })
        .catch((error: Error) => {
          setAttemptingTxn(false)
          // we only care if the error is something _other_ than the user rejected the tx
          console.error(error)
        })
    }
  }

  function modalHeader() {
    return (
      <AutoColumn gap={ 'md' } style={ { marginTop: '20px' } }>
        <RowBetween align="flex-end">
          <Text fontSize={ 24 } fontWeight={ 500 }>
            { parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) }
          </Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={ currencyA } size={ '24px' }/>
            <Text fontSize={ 24 } fontWeight={ 500 } style={ { marginLeft: '10px' } }>
              { currencyA?.symbol }
            </Text>
          </RowFixed>
        </RowBetween>
        <RowFixed>
          <Plus size="16" color={ theme.text2 }/>
        </RowFixed>
        <RowBetween align="flex-end">
          <Text fontSize={ 24 } fontWeight={ 500 }>
            { parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) }
          </Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={ currencyB } size={ '24px' }/>
            <Text fontSize={ 24 } fontWeight={ 500 } style={ { marginLeft: '10px' } }>
              { currencyB?.symbol }
            </Text>
          </RowFixed>
        </RowBetween>

        <TYPE.italic fontSize={ 12 } color={ theme.text2 } textAlign="left" padding={ '12px 0 0 0' }>
          { `Output is estimated. If the price changes by more than ${ allowedSlippage /
          100 }% your transaction will revert.` }
        </TYPE.italic>
      </AutoColumn>
    )
  }

  function modalBottom() {
    return (
      <>
        <RowBetween>
          <Text color={ theme.text2 } fontWeight={ 500 } fontSize={ 16 }>
            { 'UNI ' + currencyA?.symbol + '/' + currencyB?.symbol } Burned
          </Text>
          <RowFixed>
            <DoubleCurrencyLogo currency0={ currencyA } currency1={ currencyB } margin={ true }/>
            <Text fontWeight={ 500 } fontSize={ 16 }>
              { parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) }
            </Text>
          </RowFixed>
        </RowBetween>
        { pair && (
          <>
            <RowBetween>
              <Text color={ theme.text2 } fontWeight={ 500 } fontSize={ 16 }>
                Price
              </Text>
              <Text fontWeight={ 500 } fontSize={ 16 } color={ theme.text1 }>
                1 { currencyA?.symbol } = { tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-' } { currencyB?.symbol }
              </Text>
            </RowBetween>
            <RowBetween>
              <div/>
              <Text fontWeight={ 500 } fontSize={ 16 } color={ theme.text1 }>
                1 { currencyB?.symbol } = { tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-' } { currencyA?.symbol }
              </Text>
            </RowBetween>
          </>
        ) }
        {/*<ButtonPrimary disabled={ !(approval === ApprovalState.APPROVED || signatureData !== null) }*/}
        <ButtonPrimary disabled={false}
                       onClick={ onRemove }>
          <Text fontWeight={ 500 } fontSize={ 20 }>
            Confirm
          </Text>
        </ButtonPrimary>
      </>
    )
  }

  const pendingText = `Removing ${ parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) } ${
    currencyA?.symbol
  } and ${ parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) } ${ currencyB?.symbol }`

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString())
    },
    [onUserInput]
  )

  const oneCurrencyIsETH = currencyA === ETHER || currencyB === ETHER

  const handleSelectCurrencyA = useCallback(
    (currency: Token) => {
      if (currencyIdB && currencyId(currency) === currencyIdB) {
        history.push(`/remove/${ currencyId(currency) }/${ currencyIdA }`)
      } else {
        history.push(`/remove/${ currencyId(currency) }/${ currencyIdB }`)
      }
    },
    [currencyIdA, currencyIdB, history]
  )
  const handleSelectCurrencyB = useCallback(
    (currency: Token) => {
      if (currencyIdA && currencyId(currency) === currencyIdA) {
        history.push(`/remove/${ currencyIdB }/${ currencyId(currency) }`)
      } else {
        history.push(`/remove/${ currencyIdA }/${ currencyId(currency) }`)
      }
    },
    [currencyIdA, currencyIdB, history]
  )

  return (
    <>
      <AppBody>
        <AddRemoveTabs adding={ false }/>
        <Wrapper>
          <ConfirmationModal
            isOpen={ showConfirm }
            onDismiss={ () => {
              setShowConfirm(false)
              setSignatureData(null) // important that we clear signature data to avoid bad sigs
              // if there was a tx hash, we want to clear the input
              if (txHash) {
                onUserInput(Field.LIQUIDITY_PERCENT, '0')
              }
              setTxHash('')
            } }
            attemptingTxn={ attemptingTxn }
            hash={ txHash ? txHash : '' }
            topContent={ modalHeader }
            bottomContent={ modalBottom }
            pendingText={ pendingText }
            title="You will receive"
          />
          <AutoColumn gap="md">
            <LightCard>
              <AutoColumn gap="20px">
                <RowBetween>
                  <Text fontWeight={ 500 }>Amount</Text>
                  <ClickableText
                    fontWeight={ 500 }
                    onClick={ () => {
                      setShowDetailed(!showDetailed)
                    } }
                  >
                    { showDetailed ? 'Simple' : 'Detailed' }
                  </ClickableText>
                </RowBetween>
                <Row style={ { alignItems: 'flex-end' } }>
                  <Text fontSize={ 72 } fontWeight={ 500 }>
                    { formattedAmounts[Field.LIQUIDITY_PERCENT] }%
                  </Text>
                </Row>
                { !showDetailed && (
                  <>
                    <Slider
                      value={ Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)) }
                      onChange={ liquidityPercentChangeCallback }
                    />
                    <RowBetween>
                      <MaxButton onClick={ () => onUserInput(Field.LIQUIDITY_PERCENT, '25') } width="20%">
                        25%
                      </MaxButton>
                      <MaxButton onClick={ () => onUserInput(Field.LIQUIDITY_PERCENT, '50') } width="20%">
                        50%
                      </MaxButton>
                      <MaxButton onClick={ () => onUserInput(Field.LIQUIDITY_PERCENT, '75') } width="20%">
                        75%
                      </MaxButton>
                      <MaxButton onClick={ () => onUserInput(Field.LIQUIDITY_PERCENT, '100') } width="20%">
                        Max
                      </MaxButton>
                    </RowBetween>
                  </>
                ) }
              </AutoColumn>
            </LightCard>
            { !showDetailed && (
              <>
                <ColumnCenter>
                  <ArrowDown size="16" color={ theme.text2 }/>
                </ColumnCenter>
                <LightCard>
                  <AutoColumn gap="10px">
                    <RowBetween>
                      <Text fontSize={ 24 } fontWeight={ 500 }>
                        { formattedAmounts[Field.CURRENCY_A] || '-' }
                      </Text>
                      <RowFixed>
                        <CurrencyLogo currency={ currencyA } style={ { marginRight: '12px' } }/>
                        <Text fontSize={ 24 } fontWeight={ 500 } id="remove-liquidity-tokena-symbol">
                          { currencyA?.symbol }
                        </Text>
                      </RowFixed>
                    </RowBetween>
                    <RowBetween>
                      <Text fontSize={ 24 } fontWeight={ 500 }>
                        { formattedAmounts[Field.CURRENCY_B] || '-' }
                      </Text>
                      <RowFixed>
                        <CurrencyLogo currency={ currencyB } style={ { marginRight: '12px' } }/>
                        <Text fontSize={ 24 } fontWeight={ 500 } id="remove-liquidity-tokenb-symbol">
                          { currencyB?.symbol }
                        </Text>
                      </RowFixed>
                    </RowBetween>
                    { chainId && false ? (
                      <RowBetween style={ { justifyContent: 'flex-end' } }>
                        { oneCurrencyIsETH ? (
                          <StyledInternalLink
                            to={ `/remove/${ currencyIdA }/${
                              currencyIdB
                            }` }
                          >
                            Receive WETH
                          </StyledInternalLink>
                        ) : false ? (
                          <StyledInternalLink
                            to={ `/remove/${
                              currencyIdA
                            }/${ currencyIdB }` }
                          >
                            Receive ETH
                          </StyledInternalLink>
                        ) : null }
                      </RowBetween>
                    ) : null }
                  </AutoColumn>
                </LightCard>
              </>
            ) }

            { showDetailed && (
              <>
                <CurrencyInputPanel
                  value={ formattedAmounts[Field.LIQUIDITY] }
                  onUserInput={ onLiquidityInput }
                  onMax={ () => {
                    onUserInput(Field.LIQUIDITY_PERCENT, '100')
                  } }
                  showMaxButton={ !atMaxAmount }
                  disableCurrencySelect
                  currency={ pair?.liquidityToken }
                  pair={ pair }
                  id="liquidity-amount"
                />
                <ColumnCenter>
                  <ArrowDown size="16" color={ theme.text2 }/>
                </ColumnCenter>
                <CurrencyInputPanel
                  hideBalance={ true }
                  value={ formattedAmounts[Field.CURRENCY_A] }
                  onUserInput={ onCurrencyAInput }
                  onMax={ () => onUserInput(Field.LIQUIDITY_PERCENT, '100') }
                  showMaxButton={ !atMaxAmount }
                  currency={ currencyA }
                  label={ 'Output' }
                  onCurrencySelect={ handleSelectCurrencyA }
                  id="remove-liquidity-tokena"
                />
                <ColumnCenter>
                  <Plus size="16" color={ theme.text2 }/>
                </ColumnCenter>
                <CurrencyInputPanel
                  hideBalance={ true }
                  value={ formattedAmounts[Field.CURRENCY_B] }
                  onUserInput={ onCurrencyBInput }
                  onMax={ () => onUserInput(Field.LIQUIDITY_PERCENT, '100') }
                  showMaxButton={ !atMaxAmount }
                  currency={ currencyB }
                  label={ 'Output' }
                  onCurrencySelect={ handleSelectCurrencyB }
                  id="remove-liquidity-tokenb"
                />
              </>
            ) }
            { pair && (
              <div style={ { padding: '10px 20px' } }>
                <RowBetween>
                  Price:
                  <div>
                    1 { currencyA?.symbol } = { tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-' } { currencyB?.symbol }
                  </div>
                </RowBetween>
                <RowBetween>
                  <div/>
                  <div>
                    1 { currencyB?.symbol } ={ ' ' }
                    { tokenB
                      ? pair
                        .priceOf(tokenB)
                        .toSignificant(6)
                      : '-' }{ ' ' }
                    { currencyA?.symbol }
                  </div>
                </RowBetween>
              </div>
            ) }
            <div style={ { position: 'relative' } }>
              { !account ? (
                <ButtonLight onClick={ toggleWalletModal }>Connect Wallet</ButtonLight>
              ) : (
                <RowBetween>
                  {/*<ButtonConfirmed*/}
                  {/*  onClick={ onAttemptToApprove }*/}
                  {/*  confirmed={ approval === ApprovalState.APPROVED || signatureData !== null }*/}
                  {/*  disabled={ approval !== ApprovalState.NOT_APPROVED || signatureData !== null }*/}
                  {/*  mr="0.5rem"*/}
                  {/*  fontWeight={ 500 }*/}
                  {/*  fontSize={ 16 }*/}
                  {/*>*/}
                  {/*  { approval === ApprovalState.PENDING ? (*/}
                  {/*    <Dots>Approving</Dots>*/}
                  {/*  ) : approval === ApprovalState.APPROVED || signatureData !== null ? (*/}
                  {/*    'Approved'*/}
                  {/*  ) : (*/}
                  {/*    'Approve'*/}
                  {/*  ) }*/}
                  {/*</ButtonConfirmed>*/}
                  <ButtonError
                    onClick={ () => {
                      setShowConfirm(true)
                    } }
                    // disabled={ !isValid || (signatureData === null) }
                    disabled={ !isValid }
                    error={ !isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B] }
                  >
                    <Text fontSize={ 16 } fontWeight={ 500 }>
                      { error || 'Remove' }
                    </Text>
                  </ButtonError>
                </RowBetween>
              ) }
            </div>
          </AutoColumn>
        </Wrapper>
      </AppBody>

      { pair ? (
        <AutoColumn style={ { minWidth: '20rem', marginTop: '1rem' } }>
          <MinimalPositionCard showUnwrapped={ false } pair={ pair }/>
        </AutoColumn>
      ) : null }
    </>
  )
}
